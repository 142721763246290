<template>
  <div class="auth-content">
    <img src="~assets/images/auth/auth-top-bg.png" class="auth-top-bg" alt="" />
    <img src="~assets/images/auth/auth-bot-bg.png" class="auth-bot-bg" alt="" />
    <div style="width: 100%; height: 100%; overflow: auto; position: relative" v-if="template_status">
      <div class="prefect-content">
        <div class="profile">
          <!--  编辑按钮-->
          <div class="edit-icon flex-nowrap">
            已有账号，
            <div @click="quitLogin">
              <span class="blue">快捷登录</span>
            </div>
          </div>
          <!--  标签页-->
          <el-tabs v-model="activeName" class="el-nav">
            <!--    注册信息开始    -->
            <el-tab-pane label="完善信息" name="first">
              <!--  完善企业信息   -->
              <el-form
                v-if="circlesCreate"
                :label-position="labelPosition"
                label-width="140px"
                :model="enterpriseForm"
                ref="enterpriseForm"
                :rules="enterpriseRules"
                :disabled="userInfo.managed_circle && userInfo.managed_circle.status === 0 && userInfo.managed_circle.info != null ? true : false"
              >
                <el-form-item label="企业名称" prop="enterprise_name">
                  <el-input class="register-input" placeholder="请输入企业名称" v-model="enterpriseForm.enterprise_name"></el-input>
                </el-form-item>
                <el-form-item label="统一社会信用代码" prop="trust_code">
                  <el-input class="register-input" placeholder="请输入统一社会信用代码" v-model="enterpriseForm.trust_code"></el-input>
                </el-form-item>

                <el-form-item label="营业执照" prop="url">
                  <div v-if="userInfo.managed_circle && userInfo.managed_circle.status === 0 && userInfo.managed_circle.info != null">
                    <el-image style="width: 100px; height: 100px" :src="enterpriseForm.url" fit="contain"></el-image>
                  </div>

                  <upload-file @successUrl="resultImg($event, 'url')" :folder="'circles'" :default_value="enterpriseFormUrl" v-else></upload-file>
                </el-form-item>

                <el-form-item label="企业地址" prop="province">
                  <china-area @areaChange="areaInfo" placeholder="请输入企业地址" :code="areaCode"></china-area>
                </el-form-item>

                <el-form-item label="详细地址" prop="address">
                  <el-input class="register-input" placeholder="请输入详细地址" v-model="enterpriseForm.address"></el-input>
                </el-form-item>

                <el-form-item label="联系人姓名" prop="user_name">
                  <el-input class="basic-input" placeholder="请输入联系人姓名" v-model="enterpriseForm.user_name"></el-input>
                </el-form-item>

                <el-form-item label="性别" prop="gender">
                  <el-radio-group v-model="enterpriseForm.gender">
                    <el-radio :label="1">男</el-radio>
                    <el-radio :label="2">女</el-radio>
                  </el-radio-group>
                </el-form-item>

                <el-form-item label="联系人邮箱">
                  <el-input class="basic-input" placeholder="请输入联系人邮箱" v-model="enterpriseForm.email"></el-input>
                </el-form-item>

                <el-form-item label="联系人手机号">
                  <el-input class="basic-input" placeholder="请输入联系人手机号" v-model="enterpriseForm.mobile"></el-input>
                </el-form-item>
              </el-form>

              <!--  完善小组   -->
              <el-form
                v-else
                :label-position="labelPosition"
                label-width="120px"
                :model="circlesForm"
                :rules="circlesRules"
                ref="circlesForm"
                class="basicForm"
                :disabled="userInfo.managed_circle && userInfo.managed_circle.status === 0 && userInfo.managed_circle.info != null ? true : false"
              >
                <el-form-item label="频道名称" prop="name">
                  <el-input class="register-input" placeholder="请输入频道名称" maxlength="15" v-model="circlesForm.name"></el-input>
                </el-form-item>

                <el-form-item label="头像" prop="icon">
                  <div v-if="userInfo.managed_circle && userInfo.managed_circle.status === 0 && userInfo.managed_circle.info != null">
                    <el-image style="width: 100px; height: 100px" :src="circlesForm.icon" fit="contain"></el-image>
                  </div>
                  <upload-file @successUrl="resultImg($event, 'icon')" :folder="'circles'" v-else :default_value="circlesFormIcon" :upload_type="'img'"></upload-file>
                </el-form-item>

                <el-form-item label="简介" prop="desc">
                  <el-input class="long-input" placeholder="请输入频道简介" maxlength="200" v-model="circlesForm.desc"></el-input>
                </el-form-item>

                <el-form-item label="公告">
                  <el-input class="long-input" placeholder="请输入频道公告" type="textarea" maxlength="200" :rows="3" v-model="circlesForm.announcement"></el-input>
                </el-form-item>
                <el-form-item label="频道类型" class="is-required">
                  <!-- <el-radio-group v-model="circlesForm.is_public">
                    <el-radio :label="false">否</el-radio>
                    <el-radio :label="true">是</el-radio>
                  </el-radio-group> -->
                  <el-select v-model="circlesForm.type" style="width: 90px">
                    <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item v-if="circlesForm.type !== 'public'" label="分享次数" prop="share_count">
                  <el-input class="long-input" placeholder="请输入分享次数" type="input" v-model="circlesForm.share_count"></el-input>
                </el-form-item>
                <el-form-item class="is-required" v-if="circlesForm.type !== 'public'" label="有效时间">
                  <el-input
                    class="time_input"
                    style="width: 140px; margin-right: 10px"
                    @blur="leaveInput"
                    v-model.trim="circlesForm.invitation_code_time"
                    placeholder="请输入时长"
                  ></el-input>
                  <el-select v-model="circlesForm.unit" placeholder="单位" style="width: 90px">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <!--    注册信息开始    -->
          </el-tabs>
        </div>
        <div class="form-operation" v-if="circlesCreate">
          <el-button class="form-operation-item" round @click="circlesCreate = false">返回</el-button>
          <el-button class="form-operation-item" type="primary" round @click="enterpriseSubmit" v-if="enterprise_submit_status">确定 </el-button>
          <el-button class="form-operation-item" type="primary" round :loading="true" v-else>提交中</el-button>
        </div>
        <div class="form-operation align-center" v-else>
          <el-button class="form-operation-item" type="primary" v-if="circles_submit_status" round @click="circlesSubmit">下一步 </el-button>
          <el-button class="form-operation-item" type="primary" round :loading="true" v-else>提交中</el-button>
        </div>
      </div>
      <div class="bottom-hint-box align-center">
        {{ $static.footer_text }}
        <a href="https://beian.miit.gov.cn" target="_blank" class="records-link">{{ $static.record_no }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import uploadFile from '../../components/uploadFile';
import ChinaArea from '../../components/ChinaArea';

export default {
  name: 'Perfect',
  data() {
    var validatePass = (rule, value, callback) => {
      let checkNum = /^[0-9]\d*$/;
      if (!checkNum.test(Number(value))) {
        callback(new Error('分享次数只能是正整数'));
        // this.circlesForm.share_count = 0;
      } else {
        callback();
      }
    };
    return {
      circlesCreate: false, // 小组完善装填
      imageUrl: '',
      activeName: 'first',
      labelPosition: 'right',
      // 小组字段完善
      circlesForm: {
        name: '',
        icon: '',
        desc: '',
        announcement: '',
        share_count: 0,
        // is_public: false,
        type: 'public',
        invitation_code_time: '0',
        unit: 1,
      },
      typeList: [
        {
          value: 'public',
          label: '公开',
        },
        {
          value: 'private',
          label: '私有',
        },
        {
          value: 'benefit',
          label: '收益',
        },
      ],
      options: [
        {
          value: 1,
          label: '周',
        },
        {
          value: 2,
          label: '日',
        },
        {
          value: 3,
          label: '时',
        },
        {
          value: 4,
          label: '分',
        },
        {
          value: 5,
          label: '秒',
        },
      ],
      circlesRules: {
        share_count: [
          { required: true, message: '请输入分享次数', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' },
        ],
        name: [
          { required: true, message: '请输入小组名称', trigger: 'blur' },
          { min: 1, max: 15, message: '长度在 1 到 15 个字符', trigger: 'blur' },
        ],
        icon: [{ required: true, message: '请上传头像', trigger: 'blur' }],
        desc: [
          { required: true, message: '请输入简介', trigger: 'blur' },
          { min: 1, max: 200, message: '长度在 1 到 200 个字符', trigger: 'blur' },
        ],
        // is_public: [{ required: true, trigger: 'blur' }],
      }, // 设置必填字段
      circles_submit_status: true, // 小组提交状态
      template_status: false, // 整体渲染

      // 企业信息完善
      enterpriseForm: {
        enterprise_name: '',
        trust_code: '',
        url: '',
        province: '',
        city: '',
        area: '',
        address: '',
        gender: 1,
        user_name: '',
        email: '',
        mobile: '',
      },
      enterpriseRules: {
        enterprise_name: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' },
        ],
        trust_code: [
          { required: true, message: '请输入统一社会信用代码', trigger: 'blur' },
          { min: 1, max: 40, message: '长度在 1 到 40 个字符', trigger: 'blur' },
        ],
        url: [{ required: true, message: '请上传营业执照', trigger: 'blur' }],
        user_name: [
          { required: true, message: '请输入联系人姓名', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' },
        ],
      }, //企业信息
      areaCode: [], // 默认地址选择
      enterprise_submit_status: true,
      enterpriseFormUrl: [],
      circlesFormIcon: [],
    };
  },
  created() {
    if (localStorage.getItem('circle-uuid')) {
      if (this.userInfo && this.userInfo.id) {
        this.template_status = true;
      }
      if (this.userInfo && this.userInfo.managed_circle) {
        if (this.userInfo.managed_circle.status === 1) {
          this.$router.replace('/home');
        }
        this.circlesCreate = true;
        this.enterpriseValue();
      }
    } else {
      this.getCircles();
    }
  },

  methods: {
    // 离开输入框
    leaveInput() {
      this.checkInput();
    },
    // 有效时间检查
    checkInput() {
      let checkNum = /^[0-9]\d*$/;
      if (this.circlesForm.type !== 'public') {
        if (this.circlesForm.invitation_code_time === '' || this.circlesForm.invitation_code_time === undefined) {
          this.$message({
            message: '请输入时间',
            type: 'warning',
          });
          return false;
        } else {
          if (!checkNum.test(Number(this.circlesForm.invitation_code_time))) {
            this.$message({
              message: '时间只能是正整数',
              type: 'warning',
            });
            this.circlesForm.invitation_code_time = '0';
            return false;
          } else if (Number(this.circlesForm.invitation_code_time) > 1000) {
            this.circlesForm.invitation_code_time = '0';
            this.$message({
              message: '时间最大不能超过1000',
              type: 'warning',
            });
            return false;
          } else {
            return true;
          }
        }
      } else {
        return true;
      }
    },
    // 小组上传图标
    resultImg(url, type) {
      if (type === 'icon') {
        this.circlesForm.icon = url[0];
        this.$refs.circlesForm.clearValidate('icon');
      } else if (type === 'url') {
        this.enterpriseForm.url = url[0];
        this.$refs.enterpriseForm.clearValidate('url');
      }
    },
    // 小组确定提交
    circlesSubmit() {
      // let checkNum = /^[1-9]\d*$/;
      if (this.userInfo.managed_circle && this.userInfo.managed_circle.status === 0 && this.userInfo.managed_circle.info != null) {
        this.circlesCreate = true;
        return false;
      }
      if (this.circlesForm.type !== 'public') {
        if (this.circlesForm.unit === '') {
          this.$message({
            message: '请选择单位',
            type: 'warning',
          });
          return;
        }
      } else {
        this.circlesForm.invitation_code_time = 0;
        this.circlesForm.unit = 1;
      }

      if (this.userInfo.managed_circle && this.userInfo.managed_circle.uuid) {
        this.$refs.circlesForm.validate((valid) => {
          if (valid && this.checkInput()) {
            // if (valid) {
            this.circles_submit_status = false;
            let url = this.$api.Circles + '/' + this.userInfo.managed_circle.uuid;
            this.$http
              .put(url, this.circlesForm, true)
              .then((res) => {
                if (res.data.success) {
                  this.getCircles();
                  this.$message.success('修改成功');
                  setTimeout(() => {
                    this.circlesCreate = true;
                  }, 300);
                  setTimeout(() => {
                    this.circles_submit_status = true;
                  }, 1000);
                } else {
                  this.circles_submit_status = true;
                  this.$message.error(res.data.message);
                }
              })
              .catch((error) => {
                this.circles_submit_status = true;
                this.$message.error(error.response.data.message);
              });
          } else {
            return false;
          }
        });
        return false;
      }
      this.$refs.circlesForm.validate((valid) => {
        if (valid) {
          this.circles_submit_status = false;
          let url = this.$api.Circles;
          this.$http
            .post(url, this.circlesForm, true)
            .then((res) => {
              if (res.data.success) {
                this.getCircles();
                this.$message.success('创建成功');
                setTimeout(() => {
                  this.circlesCreate = true;
                }, 300);
                setTimeout(() => {
                  this.circles_submit_status = true;
                }, 1000);
              } else {
                this.circles_submit_status = true;
                this.$message.error(res.data.message);
              }
            })
            .catch((error) => {
              this.circles_submit_status = true;
              this.$message.error(error.response.data.message);
            });
        } else {
          return false;
        }
      });
    },
    // 企业信息提交
    enterpriseSubmit() {
      if (this.userInfo.managed_circle && this.userInfo.managed_circle.status === 0 && this.userInfo.managed_circle.info != null) {
        this.$router.push('/audit');
        return false;
      }
      if (this.userInfo.managed_circle && this.userInfo.managed_circle.info != null) {
        this.$refs.enterpriseForm.validate((valid) => {
          if (valid) {
            this.enterprise_submit_status = false;
            let url = this.$api.Circles + '/' + this.userInfo.managed_circle.uuid + '/infos';
            this.$http
              .put(url, this.enterpriseForm, true)
              .then((res) => {
                if (res.data.success) {
                  this.getCircles();
                  this.$message.success('提交成功');
                  setTimeout(() => {
                    this.$router.push('/audit');
                  }, 300);
                } else {
                  this.enterprise_submit_status = true;
                  this.$message.error(res.data.message);
                }
              })
              .catch((error) => {
                this.enterprise_submit_status = true;
                this.$message.error(error.response.data.message);
              });
          } else {
            return false;
          }
        });
        return false;
      }

      this.$refs.enterpriseForm.validate((valid) => {
        if (valid) {
          this.enterprise_submit_status = false;
          let url = this.$api.Circles + '/' + this.userInfo.managed_circle.uuid + '/infos';
          this.$http
            .post(url, this.enterpriseForm, true)
            .then((res) => {
              if (res.data.success) {
                this.getCircles();
                this.$message.success('提交成功');
                setTimeout(() => {
                  this.$router.push('/audit');
                }, 300);
              } else {
                this.enterprise_submit_status = true;
                this.$message.error(res.data.message);
              }
            })
            .catch((error) => {
              this.enterprise_submit_status = true;
              this.$message.error(error.response.data.message);
            });
        } else {
          return false;
        }
      });
    },
    // 企业地址
    areaInfo(data) {
      this.enterpriseForm.province = data[0];
      this.enterpriseForm.city = data[1];
      this.enterpriseForm.area = data[2];
    },
    // 默认赋值
    enterpriseValue() {
      let enterpriseInfo = this.userInfo.managed_circle;
      // 小组的默认值
      this.circlesForm.name = enterpriseInfo.name;
      this.circlesForm.icon = enterpriseInfo.icon;
      this.circlesForm.desc = enterpriseInfo.desc;
      this.circlesForm.announcement = enterpriseInfo.announcement;
      this.circlesForm.share_count = enterpriseInfo.share_count;
      // this.circlesForm.is_public = enterpriseInfo.is_public;
      this.circlesForm.type = enterpriseInfo.type;
      this.circlesForm.invitation_code_time = enterpriseInfo.invitation_code_time;
      this.circlesForm.unit = enterpriseInfo.unit;
      if (enterpriseInfo.icon) {
        this.circlesFormIcon = [];
        this.circlesFormIcon.push(enterpriseInfo.icon);
      }

      if (enterpriseInfo.info) {
        // 企业信息的默认值
        this.enterpriseForm.enterprise_name = enterpriseInfo.info.enterprise_name;
        this.enterpriseForm.trust_code = enterpriseInfo.info.trust_code;
        this.enterpriseForm.url = enterpriseInfo.info.url;
        this.enterpriseForm.address = enterpriseInfo.info.address;
        this.enterpriseForm.gender = enterpriseInfo.info.gender;
        this.enterpriseForm.user_name = enterpriseInfo.info.user_name;
        this.enterpriseForm.email = enterpriseInfo.info.email;
        this.enterpriseForm.mobile = enterpriseInfo.info.mobile;

        if (enterpriseInfo.info.province) {
          this.areaCode[0] = enterpriseInfo.info.province;
          this.enterpriseForm.province = enterpriseInfo.info.province;
        }
        if (enterpriseInfo.info.city) {
          this.areaCode[1] = enterpriseInfo.info.city;
          this.enterpriseForm.city = enterpriseInfo.info.city;
        }
        if (enterpriseInfo.info.area) {
          this.areaCode[2] = enterpriseInfo.info.area;
          this.enterpriseForm.area = enterpriseInfo.info.area;
        }

        if (enterpriseInfo.info.url) {
          this.enterpriseFormUrl = [];
          this.enterpriseFormUrl.push(enterpriseInfo.info.url);
        }
      }
    },
    // 退出登录
    quitLogin() {
      this.$cache.removeLoginInfo();
      this.$router.push('/login');
    },
    // 获取小组列表
    getCircles() {
      let url = this.$api.userManagedCircles;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          let _data = res.data.data;
          if (_data && _data.length) {
            if (_data.length == 1) {
              if (!localStorage.getItem('circle-uuid')) {
                localStorage.setItem('circle-uuid', _data[_data.length - 1].uuid);
              }
              setTimeout(() => {
                this.template_status = true;
              }, 500);
              this.$user.getUserInfo();
            } else {
              if (!localStorage.getItem('circle-uuid')) {
                localStorage.setItem('circle-uuid', _data[_data.length - 1].uuid);
                this.$user.getUserInfo();
              }
              //this.$router.replace('/verify')
            }
          } else {
            this.template_status = true;
          }
        }
      });
    },
  },
  watch: {
    userInfo() {
      if (this.userInfo && this.userInfo.id) {
        this.template_status = true;
      }
      if (this.userInfo && this.userInfo.managed_circle) {
        if (this.userInfo.managed_circle.status === 1) {
          this.$router.replace('/home');
        }
        this.circlesCreate = true;
        this.enterpriseValue();
      }
    },
  },

  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  components: {
    uploadFile,
    ChinaArea,
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/auth/auth.less';
@import '~assets/less/auth/perfect.less';

.auth-content {
  overflow: hidden;
}

.prefect-content {
  margin: 5% auto 0 auto;
}
.bottom-hint-box {
  a {
    margin-left: 6px;
    color: #93999f;
    text-decoration: none;
  }
}
</style>
